import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
    arabic: `'IBM Plex Sans Arabic', sans-serif`,
    englishCalligraphy: `'Parisienne', cursive`,
    arabicCalligraphy: `'Aref Ruqaa', serif`,
  },
  colors: {
    candleLight: '#f7ece9',  // Soft warm light like candle flame
    roseGold: '#e9c4b7',     // A delicate rosy tone
    desertRose: '#d6a38b',   // Desert-like subtle rose shade
    warmSand: '#c3865d',     // Earthy, warm sandy tones
    amberGlow: '#b56d44',    // Warm amber, reminiscent of candlelight
    deepCopper: '#94492e',   // A strong copper tone for text
    burntUmber: '#703520',   // Rich, deep burnt umber
    mahogany: '#4d2316',     // Dark mahogany for depth
    espresso: '#2f120b',     // Deep espresso tone, perfect for rich backgrounds
    darkEbony: '#150706',    // Near-black, very rich for strong contrasts
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 'full',
        fontWeight: 'bold',
        _hover: {
          bg: 'amberGlow',
          color: 'white',
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: 'Poppins, sans-serif',
        color: 'deepCopper',
      },
    },
    Text: {
      baseStyle: {
        fontFamily: 'Poppins, sans-serif',
        color: 'burntUmber',
      },
    },
  },
})

export default theme
