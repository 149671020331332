import React from "react";
import { Box, Text, Heading, VStack, chakra, HStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import TimeLeft from "./models/TimeLeft";

// Motion component setup for Chakra
const MotionBox = chakra(motion.div);

function Home() {
  // Countdown logic for the wedding date (April 11, 2025)
  const weddingDate = new Date("2025-04-11T00:00:00").getTime();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  function calculateTimeLeft() {
    const now = new Date().getTime();
    const difference = weddingDate - now;
    let timeLeft = {} as TimeLeft;

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }
    return timeLeft;
  }

  return (
    <Box
      position="relative"
      bgImage="url('/bg.jpeg')"
      bgSize="cover"
      bgPosition="center"
      minH="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      color="candleLight"
      _before={{
        content: `""`,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        bgGradient: "linear(to-b, rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
        zIndex: 1,
      }}
    >
      <VStack spacing={6} px={4} position="relative" zIndex={2}>
        {/* Animated Heading */}
        <MotionBox
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2 } as any}
        >
          <Heading
            fontSize={["3xl", "4xl", "5xl"]}
            fontFamily="englishCalligraphy"
            color="candleLight"
          >
            Save the Date
          </Heading>
        </MotionBox>

        {/* Wedding Date */}
        <MotionBox
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, delay: 0.5 } as any}
        >
          <Text
            fontSize={["3xl", "2xl"]}
            fontFamily="arabicCalligraphy"
            color="candleLight"
          >
            11th of April 2025
          </Text>
        </MotionBox>

        {/* Names */}
        <MotionBox
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.5, delay: 1 } as any}
        >
          <Text
            fontSize={["2xl", "3xl"]}
            fontFamily="englishCalligraphy"
            color="candleLight"
          >
            Hadi & Shmoa
          </Text>
        </MotionBox>

        {/* Countdown Timer with Creative Box */}
        <MotionBox
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.5, delay: 1.5 } as any}
          mt={8}
          fontSize="2xl"
          color="candleLight"
        >
          <Box
            bg="rgba(255, 255, 255, 0.1)"
            backdropFilter="blur(10px)"
            borderRadius="20px"
            p={6}
            shadow="lg"
            border="1px solid rgba(255, 255, 255, 0.2)"
            color="candleLight"
          >
            {timeLeft.days !== undefined ? (
              <VStack>
                <HStack spacing={4} color={"white"}>
                  <TimeBox label="Days" value={timeLeft.days} />
                  <TimeBox label="Hours" value={timeLeft.hours} />
                  <TimeBox label="Minutes" value={timeLeft.minutes} />
                  <TimeBox label="Seconds" value={timeLeft.seconds} />
                </HStack>
                <Text fontSize={["lg", "xl"]} color="candleLight" mt={4}>
                  until the big day!
                </Text>
              </VStack>
            ) : (
              <Text fontSize="2xl">It's time to celebrate!</Text>
            )}
          </Box>
        </MotionBox>
      </VStack>
    </Box>
  );
}

// A reusable component for the individual time sections (days, hours, etc.)
const TimeBox = ({ label, value }: { label: string; value: number }) => {
  return (
    <VStack
      p={3}
      borderRadius="10px"
      bg="rgba(0, 0, 0, 0.2)"
      color="candleLight"
      textAlign="center"
      w="80px"
      h="80px"
      justifyContent="center"
      boxShadow="md"
    >
      <Text fontSize="2xl" fontWeight="bold" color={"white"}>
        {value}
      </Text>
      <Text fontSize="sm" color={"white"}>{label}</Text>
    </VStack>
  );
};

export default Home;
